<template>
    <div>
        <div class="content">
            <el-form :model="dataForm" ref="cmdRealDataForm" label-width="150px" :inline="true">
                <el-form-item label="电池均衡时间" prop="equalizationTime">
                    <el-input-number v-model="dataForm.equalizationTime" :step="5" step-strictly :min="5" :max="900"
                        placeholder="5-900"></el-input-number>
                    <span class="unit">分钟</span>
                </el-form-item>
            </el-form>
        </div>
        <div class="btn-box">
            <el-button @click="submit" type="primary">设置</el-button>
        </div>
    </div>
</template>

<script>

import loading from '@/utils/cmdLoading';
import mqttService from '@/api/mqtt-service';
import message from "@/utils/message";
import global from '@/components/Global';
import {queryEsaioManagerJsonSchemaDetail} from "@/api/jsonschema/jsonSchemaApi";
import jsonSchema from '@/api/jsonschema/json-schema';

export default {

    name: 'battery-equalization-time',
    data() {

        return {

            command: {

                commandCodeR: 'get_battery_equalization_status_parameters',
                commandCodeW: 'set_battery_equalization_time'
            },
            deviceSn: '',
            dataForm: {

                equalizationTime: 5
            },
            jsonSchemaData: {}
        }
    },
    methods: {

        /**
         * 页面初始化.
         */
        initPage(deviceSn) {

            this.deviceSn = deviceSn;
            this.cmdLoading = loading.loading('电池均衡时间读取中...', 30);
            let that = this;
            let option = {

                loading: that.cmdLoading,
                data: {

                    deviceSn: that.deviceSn,
                    commandCode: that.command.commandCodeR
                },
                mqttMessageCallback: res => {

                    if (res.code === global.response_status_success_obj) {

                        that.initSuccessCallBack(res.data);
                    } else {

                        message.error('指令下发失败');
                    }
                }
            };
            setTimeout(() => {

                mqttService.disconnectMqtt();
            }, 30000);
            mqttService.sendEsaioBusinessCommand(option);
            // 查询jsonSchema
            this.queryWriteCommandJsonSchema();
        },
        /**
         * 设置提交.
         */
        submit() {

            let check = jsonSchema.validate(this.jsonSchemaData, this.dataForm);
            if (!check.pass) {

                message.error('参数错误');
                return;
            }
            this.cmdLoading = loading.loading('电池均衡时间指令下发中...', 30);
            let that = this;
            let option = {

                loading: that.cmdLoading,
                data: {

                    deviceSn: that.deviceSn,
                    commandCode: that.command.commandCodeW,
                    data: that.dataForm
                },
                mqttMessageCallback: res => {

                    that.submitSuccessCallback(res);
                }
            };
            setTimeout(() => {

                mqttService.disconnectMqtt();
            }, 30000);
            mqttService.sendEsaioBusinessCommand(option);
        },

        /**
         * 查询指令回调.
         */
        initSuccessCallBack(data) {

            this.dataForm.equalizationTime = data.equalizationTime;
        },
        /**
         * 设置指令回调.
         * @param data 指令响应数据
         */
        submitSuccessCallback(data) {

            if (data.code === global.response_status_success_obj) {

                message.success('电池均衡时间设置成功');
            } else {

                message.error('指令下发失败');
            }
        },
        /**
         * 查询写指令的的json schema校验信息
         */
        queryWriteCommandJsonSchema() {

            let param = {

                deviceSn: this.deviceSn,
                commandCode: this.command.commandCodeW
            };
            queryEsaioManagerJsonSchemaDetail(param).then((res) => {

                if (res.code === global.response_status_success_obj) {

                    this.jsonSchemaData = res.data;
                } else {

                    message.error(res.msg);
                }
            }).catch(err => {

                message.error('请求失败,请稍后再试');
                console.error(err);
            });
        }
    }
}
</script>

<style lang="scss" scoped>

.content {

    height: 490px;
    margin: 10px 0 1% 5%;
    .unit {

        padding-left: 5px;
    }
}

.btn-box {

    text-align: center;
}
</style>
